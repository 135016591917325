import React from 'react';

import { MotionConfig } from 'framer-motion';

import Seo from '../components/layout/Seo';

// markup
const SfdrPage = () => {
  return (
    <MotionConfig transition={{ duration: 0.75, type: 'tween' }}>
      <article className="defaultArticle">
        <Seo title="SFDR" description="Disclosure for Articles 3, 4 and 5 of SFDR" />
        <section>
          <h1>Disclosure for Articles 3, 4 and 5 of SFDR</h1>

          <p>
            This disclosure is made for the purposes of Articles 3, 4 and 5 of EU Regulation 2019/2088, known as the Sustainable Finance Disclosure
            Regulation (“SFDR”) as applied from 10 th March 2021.
          </p>
          <ul>
            <li>
              Article 3 (1) requires a Financial Market Participant to publish information about their policies on the integration of sustainability
              risks in their investment decision-making process.
            </li>
            <li>
              Article 4 (1) (b) requires a Financial Market Participant to disclose clear reasons for why we do not consider adverse impacts of
              investment decisions on sustainability factors at entity level.
            </li>
            <li>
              Article 5 (1) requires a Financial Market Participant to publish information on the consistency of its remuneration policy information
              with the integration of sustainability risks.
            </li>
            <li>
              “Sustainability factors” mean environmental, social and employee matters, respect for human rights, anti- corruption and anti-bribery
              matters.
            </li>
            <li>
              “Sustainability risk” means an environmental, social or governance event or condition that, if it occurs, could cause an actual or a
              potential material negative impact on the value of the investment.
            </li>
          </ul>
          <p>
            Pygmalion Capital Advisers LLP (“the Firm”) provides its portfolio management services to a select number of specialised strategies with
            differing investor requirements and across a number of jurisdictions, each with varying levels of detail on the implementation of
            consideration of sustainability risks into the processes and procedures of service providers that play a part in investment sourcing, due
            diligence and execution of deals.
          </p>
          <p>
            The Firm is committed to improving the consideration of sustainability risks factors in its investment decision making process and ongoing
            oversight of these investments. The Firm has implemented, and is implementing new initiatives towards this goal including:
          </p>
          <ul>
            <li>Development of a Responsible Investment Policy;</li>
            <li>
              Ensuring a specific inclusion in each investment proposal on whether sustainability risks have been considered (and if so, including and
              reviewing details of such considerations and the due diligence or reports that have been undertaken to investigate such risks);
            </li>
            <li>
              Encouraging attendance at seminars and workshops for staff to understand and implement better the SFDR requirements and the underlying
              principles it aims for, as well as wider ESG initiatives and market evolution and benchmarks;
            </li>
            <li>Reviewing market practice for best of breed practices and promoting these within our portfolio management service offering; and</li>
            <li>Raising more awareness of ESG matters within the operations of the Firm to engender a responsible investment culture.</li>
          </ul>
          <p>
            The Firm does not currently consider adverse impacts of investment decisions on sustainability factors at entity level. This is because
            the final investment decision making process considers a broad range of factors with the aim of achieving the optimum outcome for the
            Firm’s investors within the stated investment objectives and restrictions for each mandate, and over the relevant time horizons. The
            methodology for considerations of adverse impacts on sustainability factors for these wide range of factors are difficult to implement at
            the entity level at this present time.
          </p>
          <p>
            The Firm does not currently have a remuneration policy that is consistent with the integration of sustainability risks, except to the
            extent described below.
          </p>
          <p>
            The Firm’s staff are remunerated by way of fixed components (salary and benefits) and variable components (discretionary bonus and, in
            some cases, carried interest). Remuneration levels are justified according to performance of the individual concerned. This will be
            reviewed as appropriate on a regular basis.
          </p>
        </section>
      </article>
    </MotionConfig>
  );
};

export default SfdrPage;
